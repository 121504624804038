import Api from '@/services/Index';

const getAll = (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/staff/cards', config);
}

const printCard = (id, side = "") => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/staff/cards/print/' + id + `?side=${side}`, {responseType: 'arraybuffer'});
}

const createCard = (id, side = "") => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/staff/cards/create/'+id + `?side=${side}`, {responseType: 'arraybuffer'});
}


export default {
    getAll,
    printCard,
    createCard
}
